<i18n>
{

  "de": {

  "page-title": "Über das Projekt",

  "page-heading": "Über das Projekt",

  "paragraph-1-1": "Wiesel sind Fleischfresser und auf ausreichend Futter angewiesen. Ihre Hauptnahrung sind Wühlmäuse, die vor allem auf Wiesen, Weiden und Brachen leben. Diese Lebensräume werden zunehmend intensiver genutzt, wodurch die Wiesel ihre wichtigsten Jagdgebiete verlieren. Zudem fehlen ihnen in den grossen Feldern oft die Strukturen, um sich vor ihren Feinden, wie zum Beispiel Greifvögeln, zu schützen. Dies führte in vielen Gebieten der Schweiz zu einem merklichen Rückgang der Wiesel.",
  "paragraph-1-2": "In diesem Projekt wollten wir herausfinden, wo Wiesel im Schweizer Mittelland vorkommen. Ausserdem interessierte uns, ob die Artenzusammensetzung der Beutetiere sich zwischen den Standorten unterscheidet und ob dies einen Einfluss auf das Vorkommen der Wiesel hat.",

  "subheading-2": "Wiesel nachweisen",
  "paragraph-2": "Da Beobachtungen von Wieseln selten und schwierig zu planen sind, müssen andere, indirekte Methoden verwendet werden, um Wiesel nachzuweisen. In diesem Projekt setzten wir Wildtierkameras ein, welche bei Bewegungen ein Bild machen oder ein kurzes Video aufzeichnen. Indem wir die Wildtierkamera innerhalb einer Box platzierten, erhöhten wir die Chance, Wiesel aufzunehmen. Wir nutzten dabei das Interesse der Wiesel, Löcher und Öffnungen nach möglichen Beutetieren zu erkunden. In der Box verbleiben die Tiere in der Regel einen Moment und dadurch können diese flinken Tiere besser gefilmt werden. Zudem können so wiederholte Fehlauslösungen der Kamera durch sich bewegende Pflanzen im Hintergrund verhindert werden. Nebst Wieseln besuchten natürlich auch andere Wildtiere die Boxen. So wurden zum Beispiel auch Ringelnattern, Wühlmäuse, Iltisse und Vögel in der Box gesichtet.",

  "subheading-3": "Wieso Wiesel?",
  "paragraph-3-1": "Als Raubtiere stehen Wiesel oben in der Nahrungskette und sind Indikatoren für den Zustand eines Ökosystems. Gehen Wieselzahlen zurück so ist dies ein Indiz, dass auch die Anzahl der Beutetiere, in diesem Falle Wühlmäuse, zurück geht. Letztere sind jedoch für eine Vielzahl von anderen Tieren, eine wichtige Nahrungsgrundlage.",
  "paragraph-3-2": "Während das Hermelin als eine Art der Gruppe der Wiesel in der Schweiz noch einigermassen häufig ist, ist die zweite Art, das kleinere Mauswiesel auf der Roten Liste der bedrohten Tierarten der Schweiz als gefährdet aufgeführt. Dies heisst, dass diese Art ein hohes Risiko besitzt in der Natur in unmittelbarer Zukunft auszusterben.",
  "paragraph-3-3": "Langzeitüberwachungen von Wieseln und deren Beutepopulationen sind daher sehr wichtig für den Naturschutz. Solche Monitorings sind aber sehr aufwendig und oft nur mit der Unterstützung von vielen Freiwilligen möglich. Die Kontrolle der Fotofallenboxen wurde in diesem Projekt vor allem durch Freiwillige durchgeführt. Vielen Dank an alle, die sich für das Projekt engagiert haben!",

  "paragraph-4-2": "Auf der Meldeplattform <a href='https://wildenachbarn.ch/' target='_blank'><b>wildenachbarn.ch</b></a> werden auch Beobachtungen von Hermelin und Mauswiesel gesammelt, die schlussendlich wieder als Datengrundlage in Förderprojekte einfliessen.",
  "paragraph-4-3": "Das Projekt wurde unterstützt von:",

  "subheading-team": "Projekt Team"

  },
  "en": {

  "page-title": "About the Project",

  "page-heading": "About the Project",

  "paragraph-1-1": "Weasels are carnivores and depend on sufficient prey populations to survive. Their main food source are voles, which live mainly on meadows, pastures and fallow land. These habitats are coming under more and more intensive use, resulting in weasels losing their most valuable hunting grounds. In addition, as fields grow ever larger, they often lack the structures they need to protect themselves from their enemies, such as birds of prey. This led to a noticeable decline in weasels in many areas of Switzerland.",
  "paragraph-1-2": "In this project, we wanted to find out where weasels occur in the Swiss Midlands. We were also interested in whether the species composition of the prey differs between locations and whether this has an influence on the occurrence of weasels.",

  "subheading-2": "Detecting Weasels",
  "paragraph-2": "Since observations of weasels are rare and difficult to plan, other indirect methods must be used to detect weasels. In this project, we used wildlife cameras, which take a picture or record a short video when something is moving in front of it. By placing the wildlife camera inside a box, we increased the chance of capturing weasels. We used the tendency of the weasels to explore holes and openings for possible prey to trick them into searching our boxes and being seen by our cameras. The animals usually remain in the box for a moment, which makes it easier to film these agile animals. In addition, with this setup we greatly reduced the chance that a moving plant or person will falsely trigger our camera. Besides weasels, other wild animals also visited the boxes. For example, grass snakes, voles, polecats and birds.",

  "subheading-3": "Why Weasels?",
  "paragraph-3-1": "As predators, weasels are at the top of the food chain and are indicators of the state of an ecosystem. If the number of weasels declines, this is an indication that the number of prey animals, in this case voles, are also declining. However, the latter are an important food source for a large number of other animals.",
  "paragraph-3-2": "While the stoat, a major species in the weasel group, is still fairly common in Switzerland, the second species, the least weasel, is listed as vulnerable on Switzerland's Red List of Endangered Species. This means that this species is at high risk of extinction in nature in the immediate future.",
  "paragraph-3-3": "Long-term monitoring of weasels and their prey populations is therefore very important for nature conservation. However, such monitoring is very costly and often only possible with the support of many volunteers spending many hours in the field. The camera trap data was mainly analyzed with the help of citizen scientists. Thank you all for having helped out with this project!",

  "paragraph-4-2": "On the observation platform <a href='https://wildenachbarn.ch/' target='_blank'><b>wildenachbarn.ch</b></a> sightings of stoats and least weasels are collected. These observations will flow into conservation projects as a data basis.",
  "paragraph-4-3": "The project was supported by:",

  "subheading-team": "Project Team"

  }

}
</i18n>

<template>
  <div>
    <app-content-section class="overflow-hidden">
      <div
        class="background-wrapper background-wrapper-move-left scroll-effect scroll-effect-delayed-1"
      >
        <div class="content-wrapper">
          <div class="row row-centered row-in-background">
            <div
              class="col col-tablet-portrait-10 col-large-7 col-large-after-1 col-wrapping col-large-no-bottom-margin"
            >
              <img src="/img/graphic-intro.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="row row-reverse-large">
          <div
            class="col col-tablet-portrait-7 col-large-6 col-large-after-1 col-wrapping scroll-effect"
          >
            <div class="content-subsection">
              <h2 class="heading centered left-aligned-large">{{ $t("page-heading") }}</h2>
              <!--
              <h3 class="subheading">{{ $t('subheading-1') }}</h3>
              <h2 class="heading centered left-aligned-large">{{ $t('subheading-1') }}</h2>
               -->
              <p v-html="$t('paragraph-1-1')"></p>
              <p v-html="$t('paragraph-1-2')"></p>

              <h3 class="subheading">{{ $t("subheading-2") }}</h3>
              <p v-html="$t('paragraph-2')"></p>

              <h3 class="subheading">{{ $t("subheading-3") }}</h3>
              <p v-html="$t('paragraph-3-1')"></p>
              <p v-html="$t('paragraph-3-2')"></p>
              <p v-html="$t('paragraph-3-3')"></p>

              <!-- <h3 class="subheading">{{ $t('subheading-4') }}</h3>
              <p v-html="$t('paragraph-4-1')"></p>
              <div class="margin-bottom">
                <div class="button-group centered left-aligned-large">
                  <router-link tag="button" to="/identification" class="button button-primary" disabled>{{ $t('paragraph-4-1-button') }}</router-link>
                </div>
              </div> -->
              <p v-html="$t('paragraph-4-2')"></p>
              <p v-html="$t('paragraph-4-3')"></p>
              <div class="margin-bottom">
                <div class="logo-group">
                  <a href="https://jurapark-aargau.ch" target="_blank"
                    ><img src="/img/logo-jurapark.png" style="height: 80px"
                  /></a>
                  <a href="https://wildenachbarn.ch" target="_blank"
                    ><img src="/img/logo-wildenachbarn.jpg" style="height: 72px"
                  /></a>
                  <a href="http://www.swild.ch/" target="_blank"
                    ><img src="/img/logo-swild.png" style="height:160px"
                  /></a>
                </div>
              </div>
            </div>
            <div class="content-subsection">
              <h3 class="subheading">{{ $t("subheading-team") }}</h3>
              <ul>
                <li>Dr. Daniel Hegglin, SWILD & Universität Zürich</li>
                <li>Dr. Fabio Bontadina, SWILD & WSL</li>
                <li>Adrian Dietrich, SWILD</li>
                <li>Anouk Taucher, SWILD</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </app-content-section>

    <section-feedback
      email="help@citizenscience.ch"
      :subject="$t('site-name')"
      color="light-greyish"
    ></section-feedback>

    <section-newsletter-signup></section-newsletter-signup>

    <app-footer></app-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ContentSection from "@/components/shared/ContentSection.vue";
import Footer from "@/components/shared/Footer.vue";
import SectionNewsletterSignup from "@/components/shared/SectionNewsletterSignup";
import SectionFeedback from "../components/shared/SectionFeedback";

export default {
  name: "About",
  components: {
    SectionFeedback,
    SectionNewsletterSignup,
    "app-content-section": ContentSection,
    "app-footer": Footer,
  },
  metaInfo: function() {
    return {
      title: this.$t("page-title"),
      meta: [
        {
          property: "og:title",
          content: this.$t("page-title"),
          template: "%s | " + this.$t("site-title"),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.c3s.user,
    }),
  },
};
</script>

<style lang="scss">
@import "@/styles/theme.scss";
@import "@/styles/shared/variables.scss";

.logo-group {
  font-size: 0;
  margin: -$spacing-3;
  a {
    display: inline-block;
    margin: $spacing-3;
  }
}

.rounded {
  border-radius: $border-radius;
}
</style>
